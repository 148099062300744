$(function () {
  $('#drawer-navigation').addClass('hidden');

  // Get the navigation element
  const $nav = $("#navigation");

  // Calculate the initial position of the navigation element
  const navTop = $nav.offset().top;

  // Set the default tab as active
  $('#default-tab button').first().addClass('border-molten-caramel-900 text-molten-caramel-900');

  // Show the default tab content
  $('#default-tab-content').find('#civil').find('#tab-content').removeClass('hidden');

  // Handle tab button click events
  $('#default-tab button').on('click', function () {
    // Remove active class from all tab buttons
    $('#default-tab button').removeClass('border-molten-caramel-900 text-molten-caramel-900');
    // Add active class to the clicked tab button
    $(this).addClass('border-molten-caramel-900 text-molten-caramel-900');
    // get the target
    const target = $(this).attr('data-tabs-target');
    // Hide all tab content
    $('#default-tab-content div').find('#tab-content').addClass('hidden');
    // Show the content of the clicked tab
    $(target).find('#tab-content').removeClass('hidden');
  });

  // Function to add the "sticky" class
  function makeSticky() {
    $nav.addClass("fixed z-20 top-0 left-0");
  }

  // Function to remove the "sticky" class
  function makeUnsticky() {
    $nav.removeClass("fixed z-20 top-0 left-0");
  }

  // Function to handle scroll events
  function handleScroll() {
    const scrollTop = $(window).scrollTop();

    if (scrollTop >= navTop) {
      makeSticky();
    } else {
      makeUnsticky();
    }


    var $navItems = $nav.find("a");
    // var sections = $navItems.map(function () {
    //     var item = $(this).attr("href") != '/' ? $($(this).attr("href")) : $('#home');
    //     if (item.length) { return item; }
    //   });

    var sections = document.querySelectorAll('section');

    // Create a new Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Get the id of the intersecting section
          const sectionId = entry.target.id;

          // Update the active menu item based on the section id
          updateActiveMenuItem(sectionId);
        }
      });
    }, { threshold: 0.5 }); // Adjust the threshold as needed

    // Observe each section
    sections.forEach(section => {
      observer.observe(section);
    });
  }

  // Attach the scroll event listener
  $(window).on("scroll", handleScroll);
});

// Function to update the active menu item
function updateActiveMenuItem(sectionId) {
  // Remove the active class from all menu items
  document.querySelectorAll('.navitem').forEach(item => {
    item.classList.remove('bg-molten-caramel-900');
  });

  // Add the active class to the corresponding menu item
  const activeMenuItem = document.querySelector(`.navitem[href="#${sectionId}"]`);
  if (activeMenuItem) {
    activeMenuItem.classList.add('bg-molten-caramel-900');
  }
}

$(function () {

  $('ul.quick-links li a[href^="#"]').on("click", function (event) {
    // Prevent default anchor click behavior
    event.preventDefault();

    // Get the target section's ID from the href attribute
    const targetID = $(this).attr("href");

    // Scroll smoothly to the target section
    $("html, body").animate(
      {
        scrollTop: $(targetID).offset().top,
      },
      "slow"
    );
  });
});

$(function () {
  const drawerClose = $('#drawer-close');
  drawerClose.on('click', (e) => {
    e.preventDefault();
    $('#drawer-navigation').addClass('hidden');
  })

  const menuToggle = $("#mobile-menu-toggle");
  menuToggle.on("click", () => {
    $('#drawer-navigation').removeClass('hidden');
  });

  $("a[data-toggle='collapse']").click(function () {
    $('#drawer-navigation').addClass('hidden');
  });

  // listener on the document itself
  $(document.body).on('click', function (event) {
    // Check if the clicked element has a specific class (e.g., 'drawer-menu')
    if ($(event.target).hasClass('drawer-menu')) {
      // If the clicked element has the 'drawer-menu' class, close the drawer menu
      $('#drawer-navigation').addClass('hidden');
    }
  });
});

$(function () {

  // Select all menu links with href attributes pointing to sections
  $('navbar a[href^="#"]').on("click", function (event) {
    // Prevent default anchor click behavior
    event.preventDefault();

    // Remove the 'bg-' class from all menu items
    $('navbar a[href^="#"]').removeClass("bg-molten-caramel-900");

    // Add the 'bg-' class to the clicked menu item
    $(this).addClass("bg-molten-caramel-700");

    // Get the target section's ID from the href attribute
    const targetID = $(this).attr("href");

    // Scroll smoothly to the target section
    $("html, body").animate(
      {
        scrollTop: $(targetID).offset().top,
      },
      "slow"
    );
  });
});

// Enable smooth scrolling for mouse wheel and arrow keys
$("html, body").on("mousewheel DOMMouseScroll wheel", function (event) {
  // Check if the user is actively scrolling or using arrow keys
  if ((event.type === "mousewheel" && event.originalEvent.wheelDelta > 0)
  ) {
    $("html, body").stop().animate({ scrollTop: "-=100" }, 300); // Adjust the scroll distance and duration as needed
  } else if (
    event.type === "mousewheel" &&
    event.originalEvent.wheelDelta < 0
  ) {
    $("html, body").stop().animate({ scrollTop: "+=100" }, 300); // Adjust the scroll distance and duration as needed
  }
});

$(function () {
  $("#contact-form").on("submit", function (event) {
    event.preventDefault();

    // Gather form data
    const formData = $(this).serialize();

    // Perform client-side validation (you can add more validation as needed)
    const name = $("#name").val();
    const email = $("#email").val();
    const message = $("#message").val();

    if (name === "") {
      $("#response-message").html("<p class='text-red m-10'>Name is a required field. Please fill in all fields.</p>");
      return;
    }

    if (email === "") {
      $("#response-message").html("<p class='text-red m-10'>Email is a required field. Please fill in all fields.</p>");
      return;
    }

    if (message === "") {
      $("#response-message").html("<p class='text-red m-10'>Message is a required field. Please fill in all fields.</p>");
      return;
    }

    // check captcha
    var result = ValidateCaptcha("#captcha-code-input");
    CheckCaptcha("captcha-code-input", result);

    if (!result) {
      $("#response-message").html(
        "<p class='text-error'>CAPTCHA verification failed. Please try again.</p>"
      );
    }

    // Submit the form data to the server (replace "submit.php" with your server-side script)
    $.ajax({
      type: "POST",
      url: "./utils/send-mail.php", // Replace with the actual URL of your server-side script
      data: formData,
      success: function (response) {
        showAlertMessage('Success! Form was successfully submitted...\nSomeone from our team will keep in touch with you.');

        // Optionally, clear the form fields after successful submission
        $("#name").val("");
        $("#email").val("");
        $("#message").val("");

        CreateCaptcha('captcha-parent', 'captcha-canvas');
      },
      error: function (e) {
        // showToast("Oops!! Error occurred: "+e.message);
        showAlertMessage('Oops! Could not submit the form. Something went wrong.');
      },
    });
  });
});

function showAlertMessage(message) {
  $('#form-alert').removeClass('hidden').removeClass('animate__fadeOut').addClass('animate__fadeIn');
  $('#form-alert-message').text(message);

  setTimeout(function () {
    $('#form-alert').removeClass('animate__fadeIn').addClass('animate__fadeOut').addClass('hidden');
  }, 5000);
}

function showToast(message) {
  const toastElement = document.getElementById('toast-default');
  const existingToasts = Array.from(toastElement.children);

  if (existingToasts.length >= 1) {
    existingToasts.forEach(toast => toast.style.display = 'none');
  }

  const newToast = document.createElement('div');
  newToast.innerHTML = message;
  newToast.classList.add('flex', 'items-center', 'w-full', 'max-w-xs', 'p-4', 'text-gray-500', 'bg-white', 'rounded-lg', 'shadow', 'dark:text-gray-400', 'dark:bg-gray-800');
  newToast.style.display = 'block';
  newToast.style.top = '0';
  newToast.style.right = '0';

  toastElement.appendChild(newToast);
}

function toastMessage(type, message, icon) {
  // Display a success message or handle the response as needed

  $("#toast-icon").html(icon);
  $("#toast").removeClass("hidden");
  $("#toast").addClass(type === "success" ? "bg-green border border-green-600 rounded-md shadow-lg dark:bg-green-500" : "bg-red border border-red-600 rounded-md shadow-lg dark:bg-red-500");
  $("#toast-message").text(response);
  $("#toast-close").on("click", () => {
    $("#toast").addClass("hidden");
  })
}

function toggleCaptureValidationError(id) {
  setTimeout(function () {
    $(id).toggle();
  }, 5000);
}

$(function () {
  CreateCaptcha('captcha-parent', 'captcha-canvas');

  $('#capture-refresher').on('click', (e) => {
    CreateCaptcha('captcha-parent', 'captcha-canvas');
  });
});

var cd;

// Create Captcha
function CreateCaptcha(parentId, canvasId) {
  //$('#InvalidCapthcaError').hide();
  var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');

  var i;
  for (i = 0; i < 6; i++) {
    var a = alpha[Math.floor(Math.random() * alpha.length)];
    var b = alpha[Math.floor(Math.random() * alpha.length)];
    var c = alpha[Math.floor(Math.random() * alpha.length)];
    var d = alpha[Math.floor(Math.random() * alpha.length)];
    var e = alpha[Math.floor(Math.random() * alpha.length)];
    var f = alpha[Math.floor(Math.random() * alpha.length)];
  }
  cd = a + ' ' + b + ' ' + c + ' ' + d + ' ' + e + ' ' + f;
  $('#' + parentId).empty().append('<canvas id=' + canvasId + ' class="capcode" width="300" height="80"></canvas>')

  var c = document.getElementById(canvasId),
    ctx = c.getContext("2d"),
    x = c.width / 2,
    img = new Image();

  img.src = "../images/seamless-wavy-line-pattern-png.webp";
  img.onload = function () {
    var pattern = ctx.createPattern(img, "repeat");
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, c.width, c.height);
    ctx.font = "46px Roboto Slab";
    ctx.fillStyle = '#ccc';
    ctx.textAlign = 'center';
    ctx.setTransform(1, -0.12, 0, 1, 0, 15);
    ctx.fillText(cd, x, 55);
  };
}

// Validate Captcha
function ValidateCaptcha(id) {
  var string1 = removeSpaces(cd);
  var string2 = removeSpaces($(id).val());
  return (string1 === string2)
}

// Remove Spaces
function removeSpaces(string) {
  return string.split(' ').join('');
}

// Check Captcha
function
  CheckCaptcha(id, result) {
  const jQueryCompatibleId = "#" + id;
  if ($(jQueryCompatibleId).val() == "" || $(jQueryCompatibleId).val() == null || $(jQueryCompatibleId).val() == "undefined") {
    $('#WrongCaptchaError').text('Please enter code given below in a picture.').show();
    $(jQueryCompatibleId).focus();
  } else {
    if (result == false) {
      $('#WrongCaptchaError').text('Invalid Captcha! Please try again.').show();
      // CreateCaptcha();
      $(jQueryCompatibleId).focus().select();
    }
    else {
      $(jQueryCompatibleId).val('').attr('place-holder', 'Enter Captcha - Case Sensitive');
      // CreateCaptcha(jQueryCompatibleId);
      $('#WrongCaptchaError').fadeOut(100);
      $('#SuccessMessage').fadeIn(500).css('display', 'block').delay(5000).fadeOut(250);
    }
  }
}


function CreateCaptcha(parentId, canvasId) {
  //$('#InvalidCapthcaError').hide();
  var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');

  var i;
  for (i = 0; i < 6; i++) {
    var a = alpha[Math.floor(Math.random() * alpha.length)];
    var b = alpha[Math.floor(Math.random() * alpha.length)];
    var c = alpha[Math.floor(Math.random() * alpha.length)];
    var d = alpha[Math.floor(Math.random() * alpha.length)];
    var e = alpha[Math.floor(Math.random() * alpha.length)];
    var f = alpha[Math.floor(Math.random() * alpha.length)];
  }
  cd = a + ' ' + b + ' ' + c + ' ' + d + ' ' + e + ' ' + f;
  $('#' + parentId).empty().append('<canvas id=' + canvasId + ' class="capcode" width="300" height="80"></canvas>')

  var c = document.getElementById(canvasId),
    ctx = c.getContext("2d"),
    x = c.width / 2,
    img = new Image();

  img.src = "../images/seamless-wavy-line-pattern-png.webp";
  img.onload = function () {
    var pattern = ctx.createPattern(img, "repeat");
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, c.width, c.height);
    ctx.font = "46px Roboto Slab";
    ctx.fillStyle = '#ccc';
    ctx.textAlign = 'center';
    ctx.setTransform(1, -0.12, 0, 1, 0, 15);
    ctx.fillText(cd, x, 55);
  };
}

// Validate Captcha
function ValidateCaptcha(id) {
  var string1 = removeSpaces(cd);
  var string2 = removeSpaces($(id).val());
  return (string1 == string2)
}

// Remove Spaces
function removeSpaces(string) {
  return string.split(' ').join('');
}

// Check Captcha
function CheckCaptcha(id, result) {
  const jQueryCompatibleId = "#" + id;
  if ($(jQueryCompatibleId).val() == "" || $(jQueryCompatibleId).val() == null || $(jQueryCompatibleId).val() == "undefined") {
    $('#WrongCaptchaError').text('Please enter code given below in a picture.').show();
    $(jQueryCompatibleId).focus();
  } else {
    if (result == false) {
      $('#WrongCaptchaError').text('Invalid Captcha! Please try again.').show();
      // CreateCaptcha();
      $(jQueryCompatibleId).focus().select();
    }
    else {
      $(jQueryCompatibleId).val('').attr('place-holder', 'Enter Captcha - Case Sensitive');
      // CreateCaptcha(jQueryCompatibleId);
      $('#WrongCaptchaError').fadeOut(100);
      $('#SuccessMessage').fadeIn(500).css('display', 'block').delay(5000).fadeOut(250);
    }
  }
}